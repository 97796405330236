import React, { useReducer } from "react"

import { AppReducer } from "./AppReducer"
import Modal from "../components/Elements/Modal"
import SlidingModal from "../components/Elements/SlidingModal"
import Toast from "../components/Layout/Toast"
import Disclaimer from "../components/Layout/Disclaimer"

export const initialState = {
  documents: [],
  activeDocument: null,
  scpwdId: {
    front: {},
    back: {},
  },
  govId: {
    front: {},
    back: {},
  },
  client: "",
  orderingFor: null,
  updatedPrescription: {
    medicines: [],
    cancelledMedicines: [],
    agreeToConsent: [],
  },
  updatePersonalDetails: {
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      houseNumber: "",
      officeAddress: {
        label: "",
        value: "",
      },
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
      barangay: {
        label: "",
        value: "",
      },
      notes: "",
    },
  },
  epharmacy: {
    agreeToConsent: [],
    medicines: [],
    hasPartnerRequest: false,
    isMedGrocerWellnessPatient: false,
    coupon: null,
    deliveryAddress: {
      addressType: "",
      streetAddress: "",
      houseNumber: "",
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
      barangay: {
        label: "",
        value: "",
      },
      notes: "",
    },
    firstName: "",
    middleName: "",
    lastName: "",
    employeeNumber: "",
    mobileNumber: "",
    emailAddress: "",
    scpwdIDNumber: "",
    spcwdId: "",
    civilStatus: "",
    beneficiaryName: "",
    beneficiaryRelationship: "",
    birthday: {
      month: { value: "", label: "" },
      date: { value: "", label: "" },
      year: "",
    },
    paymentOption: "Bank Transfer/Deposit - UnionBank",
    changeFor: "",
    ifMedicineNotAvailable: { value: "", label: "" },
    orderNotes: "",
    healthSurvey: {
      teleconsultSurvey: {
        consultingDoctorOnline: [],
        numberOfConsultations: "",
        howOftenYouConsult: "",
        openForConsulting: [],
        howMuchWillingToPay: "",
      },
    },
    hospitalName: { value: "" },
    prescribingDoctor: "",
    selectedBank: "UnionBank",
  },
  flexmed: {
    agreeToMechanics: false,
    fullName: "",
    employeeNumber: "",
    mobileNumber: "",
    emailAddress: "",
    civilStatus: "",
    beneficiaryName: "",
    beneficiaryRelationship: "",
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  slidingModal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    isClosed: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
    isMedToast: false,
  },
  disclaimer: {
    isActive: false,
    header: null,
    message: null,
    color: null,
  },
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleCloseSlidingModal = async (event) => {
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isBodyOnly={state.modal.isBodyOnly}
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
      >
        {state.modal.content}
      </Modal>
      <SlidingModal
        isModalActive={state.slidingModal.isActive}
        isFullheight={state.slidingModal.isFullheight}
        isModalClosed={state.slidingModal.isClosed}
        hideModal={() => dispatch({ type: "HIDE_SLIDING_MODAL" })}
        closeModal={handleCloseSlidingModal}
        heading={state.slidingModal.heading}
        modalHeaderClass={state.slidingModal.headerClass}
        modalBackground={state.slidingModal.background}
        hideCloseButton={state.slidingModal.hideCloseButton}
        isCard={state.slidingModal.isCard}
      >
        {state.slidingModal.content}
      </SlidingModal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
        isMedToast={state.toast.isMedToast}
      />
      {children}
      <Disclaimer
        isActive={state.disclaimer.isActive}
        message={state.disclaimer.message}
        header={state.disclaimer.header}
        color={state.disclaimer.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      />
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
