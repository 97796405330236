import React, { Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faTags } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/cart.module.scss"

const DisplayCoupon = ({ coupon, dispatch, state }) => {
  const handleOnRemoveCoupon = async () => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, coupon: null },
      },
    })
    await dispatch({ type: "REMOVE_COUPON" })
  }
  return (
    <Fragment>
      <hr
        className={classNames("has-background-light", styles["row__divider"])}
      />
      <div className={classNames("is-flex px-1", styles["coupon"])}>
        <div>
          <span className="icon has-text-primary">
            <FontAwesomeIcon icon={faTags} />
          </span>
          <span className="has-text-weight-bold">Code: </span>
          {coupon.code}
        </div>
        <div>
          <button
            className="button is-danger is-outlined"
            onClick={handleOnRemoveCoupon}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
      {coupon.error && (
        <div className="has-text-danger is-size-7 mt-1 px-1">
          Sorry, you have redeemed this coupon already. Please enter a different
          coupon.
        </div>
      )}
      {coupon.code === "SCPWD" && (
        <p className="has-text-danger help mx-1 mt-1">
          SCPWD orders processed only if the following are uploaded: SCPWD ID
          (front and back), prescription on doctor's pad.
        </p>
      )}
      {coupon.code === "MGMHMEDS" && (
        <p className="has-text-danger help mx-1 mt-1">
        MGMHMEDS orders processed only if the following are uploaded: GOV ID
        (front and back), prescription on doctor's pad.
      </p>
      )}
    </Fragment>
  )
}

export default DisplayCoupon
